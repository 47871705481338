import { Link } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React, { Component } from "react";
import CustomProductPage from "../components/CustomProduct";
import Footer from "../components/footer.js";
import Header from "../components/Header";
import Layout from "../components/layout";
import withLocation from "../components/Router/withLocation";
import Text from "../components/Theme";

class CustomProductPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      didError: false,
      error: undefined,
      map: undefined,
    };
  }

  componentDidMount() {
    const productId = this.getProductId();
    const url =
      process.env.NODE_ENV === "development"
        ? `http://localhost:8000/api/public/maps/${productId}`
        : `https://daily-overview.herokuapp.com/api/public/maps/${productId}`;
    // const url = `https://daily-overview.herokuapp.com/api/public/maps/${productId}`;
    this.setState({
      isLoading: true,
    });
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const { success, map, error } = data;
        if (success) {
          this.setState({ isLoading: false, map });
        } else {
          this.setState({
            isLoading: false,
            didError: true,
            error,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          didError: true,
          error,
        });
      });
  }
  getProductId = () => {
    const {
      location: { search },
    } = this.props;
    const params = new URLSearchParams(search);
    return params.get("productId");
  };

  render() {
    const { isLoading, map } = this.state;
    return (
      <Layout>
        <HelmetDatoCms>
          <title>Overview Custom Print Creator</title>
          <meta property="og:title" content="Overview Custom Print Creator" />
          <meta
            property="og:image"
            content={require("../images/custom-order/Icon.jpg")}
          />
        </HelmetDatoCms>

        <Header content_color="black" />

        {isLoading ? <Text>Loading...</Text> : <React.Fragment />}

        {map ? <CustomProductPage map={map} /> : <React.Fragment />}

        {!map && !isLoading ? (
          <Text sub small style={{ marginLeft: 24 }}>
            No map was found at this ID. Create a{" "}
            <Link className="main-nav-link" to="/custom-order">
              custom map
            </Link>
            . Please <a>contact us</a> if this problem persists.
          </Text>
        ) : (
          <React.Fragment />
        )}

        <Footer />
      </Layout>
    );
  }
}

export default withLocation(CustomProductPageContainer);
