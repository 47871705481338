import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel";
import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import media from "styled-media-query";
import Text, { Grid } from "../Theme";
import RequestAssistance from "./request-assistance";

const Controls = styled.div`
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 44px;
  position: absolute;
  transition: all 100ms ease-in;
  button {
    padding: 10px;
    background-color: transparent;

    transition: all 100ms ease-in;
    color: #fff;
    border-radius: 100px;
    &:hover {
      opacity: 1;
      transform: scale(1.01);
    }
    &:disabled {
      opacity: 0;
    }
    ${media.lessThan("large")`
            padding: 15px;
        `}
  }
  .carousel__back-button {
    font-size: 24px;
    position: absolute;
    left: 20px;
    ${media.lessThan("large")`
            left: 0px;
        `}

    &:hover {
      transform: scale(1.2);
    }
    &:active {
      top: 2px;
    }
  }
  .carousel__next-button {
    font-size: 24px;
    position: absolute;

    right: 20px;

    ${media.lessThan("large")`
            right: 0px;
        `}

    &:hover {
      transform: scale(1.2);
    }
    &:active {
      top: 2px;
    }
  }
  button:focus {
    outline: none;
    box-shadow: 0px 0 0px 0px #406f64;
  }
`;

const ProductHero = styled.div`
  ${Grid};
  min-height: 70vh;

  .div-block-3 {
    grid-column: 2/7 !important;
    select {
      max-width: 200px;
    }
    .snipcart-add-item {
      background-color: #000;
      color: #fff;
      padding: 15px 60px;
    }
  }
  .carousel {
    &:hover {
      button {
        background: rgba(0, 0, 0, 0.4);
      }
    }
    .carousel__inner-slide {
      padding: 5px;
      ${media.lessThan("large")`
                padding: 0px;
            `}

      .gatsby-image-wrapper {
        height: 100%;
        img {
          object-fit: contain !important;
        }
      }
    }
  }
`;

const LoadingMessage = styled.div`
  padding-right: 50px;
  padding-left: 50px;
  margin-bottom: 100px;
  width: 100%;
`;

const ProductDetails = styled.div`
  ${Grid};
  align-items: start;
`;

const ProductDesc = styled.div`
  padding-top: 40px;
  h3 {
    margin-bottom: 40px;
  }
`;

const Shipping = styled.div`
  grid-column: 2/13;
  padding-top: 60px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 120px;
  h3 {
    margin-bottom: 40px;
  }
`;

class CustomProductPage extends React.Component {
  dollarFormat = (amount, options = {}) => {
    const { precision = 2, returnZero = true, dollarSign = true } = options;

    const num = Number(amount);
    if (!(num || returnZero)) return "$0.00";

    const floatNum = num.toFixed(precision);

    const formattedNum = `${dollarSign ? "$" : ""}${floatNum}`;
    return formattedNum;
  };

  clickCopy = (e) => {
    e.preventDefault();
    var copyText = window.location.href;

    document.addEventListener(
      "copy",
      function(e) {
        e.clipboardData.setData("text/plain", copyText);
        e.preventDefault();
      },
      true
    );

    document.execCommand("copy");
    console.log("copied text : ", copyText);
    alert("copied text: " + copyText);
  };

  render() {
    const { map } = this.props;
    const selectedSize = `${map.print_width}x${map.print_height}`;
    const images = [map.image];

    if (map.status !== "COMPLETE") {
      return (
        <LoadingMessage>
          <h1 className="headline" style={{ marginTop: 20, marginBottom: 60 }}>
            Thank you for submitting your request!
          </h1>
          <Text sub style={{ maxWidth: 1200 }}>
            If you haven't received an email from us within a day of your
            request, please email us at{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="mailto:info@over-view.com"
            >
              info@over-view.com
            </a>
            .
          </Text>
          <Text sub style={{ maxWidth: 1200, marginTop: 30 }}>
            <a style={{ textDecoration: "underline" }} href="/custom">
              Go back
            </a>{" "}
            to submit another request.
          </Text>
        </LoadingMessage>
      );
    }

    return (
      <div>
        <div
          hidden
          id="snipcart"
          data-api-key="NTA0ZThmMzEtYTc0Ny00MjU0LWI4NzgtNDM4NjRhMDc2ZWM5NjM2OTYyNjc0NjkyMDQyOTE4"
        ></div>

        <Helmet>
          <script
            async
            src="https://cdn.snipcart.com/themes/v3.0.25/default/snipcart.js"
          ></script>
          <link
            rel="stylesheet"
            href="https://cdn.snipcart.com/themes/v3.0.25/default/snipcart.css"
          />
        </Helmet>

        <ProductHero>
          <div id="w-node-911e36131b53-4a37690d">
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={images.length}
              visibleSlides={1}
            >
              <Slider>
                {images.map((img, index) => (
                  <Slide index={index} key={index}>
                    <img src={img} alt="" />
                  </Slide>
                ))}
              </Slider>
              <DotGroup></DotGroup>
              <Controls>
                <ButtonBack>&larr;</ButtonBack>
                <ButtonNext>&rarr;</ButtonNext>
              </Controls>
            </CarouselProvider>
          </div>

          <div id="w-node-8dcc4f1a6824-4a37690d" className="div-block-3">
            <h1 className="headline">Custom Print</h1>
            <Text sub style={{ fontSize: 20 }}>
              Created by {map.name}
            </Text>
            <div className="caption">
              {selectedSize} | {this.dollarFormat(map.price)}
            </div>

            <button
              className="Product snipcart-add-item caption"
              data-item-image={map.image}
              data-item-id={map.id}
              data-item-price={map.price}
              data-item-name={`${map.name}'s Custom Print`}
              data-item-url={`https://daily-overview.herokuapp.com/api/public/maps/${map.id}/validate`}
              data-item-stackable="false"
              data-item-shippable="true"
            >
              Buy Now &rarr;
            </button>
            <ProductDesc>
              <Text caption>Description</Text>
              <Text small>
                Here’s your custom print! If what you see looks good, you can
                order through the link above. If you see an issue with your
                print, please let us know how you’d like us to fix it in the box
                below.{" "}
              </Text>
            </ProductDesc>

            <ProductDesc>
              <Text caption>Share your custom print</Text>
              <p className="caption daily-links">
                <a
                  href={`mailto:?subject=Check out this satellite image I took on Overview&body=Check out this satellite image I took on Overview using their custom map tool: https://www.over-view.com/overviews/${window.location.href}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Email
                </a>
                {" | "}
                <a href="#" onClick={this.clickCopy}>
                  Copy Link
                </a>
              </p>
            </ProductDesc>
          </div>
        </ProductHero>
        <ProductDetails>
          <Shipping>
            <RequestAssistance map={map} />
          </Shipping>
          <Shipping>
            <Text caption>SHIPPING & PRINTING INFO</Text>
            <Text small>
              <ul>
                <li>Free shipping is included within the United States.</li>
                <li>
                  International shipping is available for an additional fee ---
                  please contact us if you do not see your country at checkout.
                </li>
                <li>
                  Prints are completed on Epson Professional Luster Paper by the
                  professionals at Cloud Printing in New York City.
                </li>
                <li>
                  Please note that prints are non-refundable, not framed, and
                  the coloration may vary slightly from what appears on your
                  screen.
                </li>
              </ul>
            </Text>
          </Shipping>
        </ProductDetails>
      </div>
    );
  }
}

export default CustomProductPage;
