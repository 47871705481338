import React from "react";
import styled from "styled-components";
import Text from "../Theme";

const HelpButton = styled.button`
  background-color: #333;
  color: #fff;
  padding: 6px 12px;
`;

const Input = styled.textarea`
  width: 100%;
  max-width: 380px;
  padding: 10px;
  border: 0px;
  border-radius: 2px;
  background-color: #f7f7f7;
`;

class RequestAssistance extends React.Component {
  state = {
    note: "",
    isLoading: false,
    didError: false,
    error: undefined,
    submittedMessage: false,
  };

  requestAssistance = (e) => {
    e.preventDefault();
    const { map } = this.props;
    const productId = map.id;
    const REQUEST_ASSISTANCE_ENDPOINT =
      process.env.NODE_ENV === "development"
        ? `http://localhost:8000/api/public/maps/${productId}/help`
        : `https://daily-overview.herokuapp.com/api/public/maps/${productId}/help`;

    fetch(REQUEST_ASSISTANCE_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        note: this.state.note,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const { success, id, error } = data;
        if (success) {
          this.setState({ isSaving: false, submittedMessage: true });
        } else {
          this.setState({
            isSaving: false,
            didError: true,
            error,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isSaving: false,
          didError: true,
          error,
        });
      });
  };

  updateNote = (event) => {
    this.setState({
      note: event.target.value,
    });
  };

  render() {
    const { note, isLoading, submittedMessage, didError, error } = this.state;
    const submitDisabled = note.length === 0 || isLoading || submittedMessage;

    let buttonLabel = "Submit";
    if (isLoading) buttonLabel = "Loading...";
    if (submittedMessage) buttonLabel = "Sent!";

    return (
      <div>
        <Text caption>Change Request</Text>
        <Text small>
          Please let us know any changes are needed on the image seen above.
        </Text>
        <form onSubmit={this.requestAssistance}>
          <Input
            type="text"
            onChange={this.updateNote}
            placeholder="e.g. Image color is inconsistent"
            value={note || ""}
          />
          <br />
          <HelpButton disabled={submitDisabled}>{buttonLabel}</HelpButton>
          {didError ? (
            <Text small>
              {error}
              <br />
              There was an error sending your message. If this problem persists,
              please contact us at info@over-view.com.
            </Text>
          ) : (
            <React.Fragment />
          )}
          {submittedMessage ? (
            <Text small>
              Change request received. We will review your request and email you
              with an updated product.
            </Text>
          ) : (
            <React.Fragment />
          )}
        </form>
      </div>
    );
  }
}

export default RequestAssistance;
